import doge from '@/assets/images/home/doge1.png'
import doge2 from '@/assets/images/home/doge2.png'
import aboutAllInDoge from '@/assets/images/home/aboutAllInDoge.png'
import welcome from '@/assets/images/home/welcome.png'
import ourCulture from '@/assets/images/home/ourCulture.png'
import media from '@/assets/images/home/media.png'

import culture01 from '@/assets/images/home/01.png'
import culture02 from '@/assets/images/home/02.png'
import culture03 from '@/assets/images/home/03.png'
import tokenomics from '@/assets/images/home/Tokenomics.png'
import tokenomics_bg from '@/assets/images/home/tokenomics_bg.png'
import token_total from '@/assets/images/home/token_total.png'
import fomo from '@/assets/images/home/fomo.png'
import whyJoin from '@/assets/images/home/whyJoin.png'

import continuously from '@/assets/images/home/continuously.png'
import global from '@/assets/images/home/global.png'
import fearless from '@/assets/images/home/fearless.png'

import number1 from '@/assets/images/home/number1.png'
import number2 from '@/assets/images/home/number2.png'
import number3 from '@/assets/images/home/number3.png'

import dont from '@/assets/images/home/dont.png'

import opp from '@/assets/images/home/Opportunities.png'

import tg from '@/assets/images/header/tg.svg'
import x from '@/assets/images/header/x.svg'

import launch from '@/assets/images/home/launch.png'
import no from '@/assets/images/home/no.png'

import circle from '@/assets/images/home/circle.png'

import dont_mobile from '@/assets/images/home/dontMobile.png'
import a_mobile from '@/assets/images/home/a_mobile.png'

export const Home = () => {
  return (
    <>
      <div className='block md:hidden w-full'>
        <div className='text-[#fff] px-[35px]'>
          <img className='block pt-[100px] mx-[auto]' src={welcome} alt="" />
          <p className='mt-[20px] text-[black] text-center'>The global crypto trading MEME project initiated by Dogecoin traders，let us enter a crypto world full of challenges and opportunities。</p>
        </div>
        <div className='relative mt-[33px] flex flex-col items-center w-full justify-start gap-[16px]'>
          <p className='rounded-[6px] border-[2px] border-b-[3px] border-[black] w-[250px] h-[43px] bg-[#ED3B23] flex items-center top-[0px] left-[0px] justify-center text-[16px]' onClick={() => window.open('https://x.com/allin_doge_', '__blank')}>Join Community</p>
          <p className='rounded-[6px] border-[2px] border-b-[3px] border-[black] w-[250px] h-[43px] bg-[white] flex items-center top-[0px] left-[0px] justify-center text-[16px]'>Go to Swap</p>
        </div>
        <img className='px-[16px]' src={doge} alt="" />
        <div className='border-[#000] h-[48px] bg-[#DFA504] flex items-center text-[16px] flex justify-around  text-[white]'>
          <span>All in Doge</span>
          <span>All in Doge</span>
          <span>All in Doge</span>
          <span>All in Doge</span>
        </div>
        <div className='bg-[rgb(255,243,212)] py-[21px] px-[22px]'>
          <img src={media} className='w-full' alt="" />
          <img className="w-[227px] mt-[20px]" src={aboutAllInDoge} alt="" />
          <p className='mt-[20px] leading-[1rem]'>All in Doge is not only a crypto project, but also a carnival community of global crypto traders. We come from all over the world, and our only goal is to go all out and move forward fearlessly!</p>
          <p className='mt-[20px] leading-[1rem]'>We have no roadmap and no ideals. We only care about the current market, focusing on trading, investment research, and the most important things in life - watching beauties and masturbating. We know that the crypto market is changing rapidly, and only with an **"All In"** mentality can we seize every opportunity and achieve extraordinary results.</p>
          <div className='relative mt-[33px] flex w-full justify-start gap-[16px]'>
            <p className='rounded-[6px] border-[2px] border-b-[3px] border-[black] flex-1 h-[43px] bg-[#ED3B23] flex items-center top-[0px] left-[0px] justify-center text-[16px]' onClick={() => window.open('https://x.com/allin_doge_', '__blank')}>Join Community</p>
            <p className='rounded-[6px] border-[2px] border-b-[3px] border-[black] flex-1 h-[43px] bg-[#FDBA01] flex items-center top-[0px] left-[0px] justify-center text-[16px]'>Go to Swap</p>
          </div>
        </div>
        <div className='mt-[54px] relative  mx-auto px-[16px] pb-[32px]'>
          <img className='w-[159px]' src={ourCulture} alt="" />
          <p className='mt-[23px] text-[12px] leading-[1rem]'>At All in Doge, we advocate an extreme trading culture - All in. Only by making the most of your investment can you reap the most rewards. Not being half-hearted, leaving no stone unturned, daring to take risks, and daring to challenge are the true wisdom of a trader.</p>
          <div className='flex justify-between  gap-[31px] mt-[52px] flex-col'>
            <div className='flex-auto w-[334px]'>
              <img className='w-full' src={culture01} alt="" />
              <p className='rounded-[6px] mt-[31px] mx-auto border-[2px] border-b-[3px] border-[black] w-full h-[43px] bg-[#ED3B23] flex items-center top-[0px] left-[0px] justify-center text-[16px]'>No batch opening</p>
              <p className='w-full px-[10px] mt-[16px]  leading-[1rem]'>
                We believe that once you see an opportunity, you should immediately go all in. No waiting, no retreat, just full speed ahead.
              </p>
            </div>
            <div className='flex-auto w-[334px]'>
              <img className='w-full' src={culture02} alt="" />
              <p className='w-full rounded-[6px] mt-[31px] mx-auto border-[2px] border-b-[3px] border-[black] w-full h-[43px] bg-[#ED3B23] flex items-center top-[0px] left-[0px] justify-center text-[16px]'>No stop loss</p>
              <p className='w-full px-[10px] mt-[16px]  leading-[1rem] '>
                The market may go up or down, but every operation of All in Doge is an all-out attack. Persevere to the end and become the real winner!
              </p>
            </div>
            <div className='flex-auto w-[334px]'>
              <img className='w-full' src={culture03} alt="" />
              <p className='rounded-[6px] w-full mt-[31px] mx-auto border-[2px] border-b-[3px] border-[black]w-full h-[43px] bg-[#ED3B23] flex items-center top-[0px] left-[0px] justify-center text-[16px]'>Community Wisdom</p>
              <p className='w-full px-[10px] mt-[16px]  leading-[1rem]'>
                We encourage every member to share their market insights, trading experience, and even investment research strategies. We are not just an investment platform, but a hotbed for the collision of ideas. The wisdom of trading comes from every one of you who dares to go all out.
              </p>
            </div>
          </div>
        </div>
        <div className='bg-[rgb(255,243,212)] px-[16px] pt-[32px] pb-[150px] relative'>
          <img className='w-[150px]' src={tokenomics} alt="" />
          <div className='mt-[20px] rounded-[6px] bg-[white] left-[0] top-[0] border-[3px] border-[black] px-[40px] py-[42px]'>
            <div className='flex flex-col'>
              <img src={launch} alt="" />
              <p className='w-[300px] leading-[1rem] mt-[30px] text-[12px]'>
                We have no pre-sale, no reserve, and every token comes from real market demand. Everyone has the opportunity to participate, it is fair and transparent.
              </p>
            </div>
          </div>
          <div className='mt-[20px] rounded-[6px] bg-[white] right-[0] bottom-[0] border-[3px] border-[black] px-[40px] py-[42px]'>
            <div className='flex flex-col'>
              <img src={no} className='w-[161px] mx-auto' alt="" />
              <p className='w-[300px] leading-[1rem] mt-[30px] text-[12px]'>
                We don’t have any early ideals or plans. We only focus on current transactions. You can enter the market at any time and go all in at any time.
              </p>
            </div>
          </div>
          <p className='text-[41px] text-center mt-[10px]'>100,000,000,000</p>
          <img className='block mx-auto w-[104px]' src={token_total} alt="" />
          <img className='absolute bottom-[0px] left-[0px] z-[0]' src={tokenomics_bg} alt="" />
        </div>
        <div className='w-full bg-[rgb(255,243,212)] relative'>
          <div className='bg-[#FDBA01] rounded-tl-[36px] rounded-tr-[36px] px-[16px]'>
            <div className='mx-auto border-[1px] border-[transparent]'>
              <div className='border-[4px] border-b-[4px]  mt-[-80px] px-[36px] py-[36px] rounded-[18px] border-[black] bg-[white] z-10000'>
                <img className='w-[280px]' src={fomo} alt="" />
                <p className='text-[12px] mt-[20px] text-center'>
                  Do you often see those money-making opportunities in the market pass by? Do you regret not taking action in advance when you see others making money?
                  All in Doge, so that you will never miss any trading opportunities. What we do is not just investment, but also a game about decision-making, courage and opportunity. Here, every action is full of possibilities, and every investment is full of power!
                  If you have made up your mind and don’t want to miss any opportunity to break out, then All in Doge is your only choice! Join us and start your own crypto trading journey!
                </p>
                <p className='mx-auto mt-[35px] rounded-[6px] border-[2px] border-b-[3px] border-[black] w-[158px] h-[43px] bg-[#ED3B23] flex items-center top-[0px] left-[0px] justify-center text-[16px]' onClick={() => window.open('https://x.com/allin_doge_', '__blank')}>Join Community</p>
              </div>
            </div>
          </div>
        </div>
        <div className='mt-[54px] pb-[42px]'>
          <div className='max-w-[1280px] mx-auto px-[16px]'>
            <img className='mx-auto' src={whyJoin} alt="" />
            <div className='flex flex-col mt-[37px] gap-[16px]'>
              <div className='flex-1 border-[3px] border-[black] rounded-[12px] px-[31px] py-[37px] bg-[white]'>
                <div className='flex justify-between h-[96px]'>
                  <img className='w-[229px]  h-[96px]' src={fearless} alt="" />
                  <img src={number1} className='w-[33px] h-[32px]' alt="" />
                </div>
                <p className='mt-[34px] leading-[1rem]'>
                  We believe that only by fully investing can we get the biggest return in this ever-changing market. You may have missed some opportunities, but today, you can still seize the present, all in!
                </p>
              </div>
              <div className='flex-1 border-[3px] border-[black] rounded-[12px] px-[31px] py-[37px] bg-[white]'>
                <div className='flex justify-between  h-[96px]'>
                  <img src={global} className='h-[64px]' alt="" />
                  <img src={number2} className='w-[33px] h-[32px]' alt="" />
                </div>
                <p className='mt-[34px] leading-[1rem]'>
                  Traders from all over the world gather together. There is no indifference, no walls, only partners who understand each other and share the fun and wisdom of trading with you.                </p>
              </div>
              <div className='flex-1 border-[3px] border-[black] rounded-[12px] px-[31px] py-[37px] bg-[white]'>
                <div className='flex justify-between h-[96px]'>
                  <img className='w-[229px] h-[96px]' src={continuously} alt="" />
                  <img src={number3} className='w-[33px] h-[32px]' alt="" />
                </div>
                <p className='mt-[34px] leading-[1rem]'>
                  In All in Doge, you are no longer alone. You can discuss market trends, analyze market conditions, and share trading strategies with other members of the community at any time. There are no "experts" here, only partners who are willing to share and learn.                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='bg-[black] pt-[49px] pb-[49px] px-[36px]'>
          <img className='mx-auto' src={dont_mobile} alt="" />
          <img className='mt-[20px] mx-auto' src={a_mobile} alt="" />
          <div className='flex mt-[30px] justify-center gap-[8px]'>
            <img className='w-[32px] cursor-pointer border-[2px] rounded-[3px] border-black' src={x} alt="" onClick={() => window.open('https://x.com/allin_doge_', '__blank')} />
            <img className='w-[32px] cursor-pointer border-[2px] rounded-[3px] border-black' src={tg} alt="" onClick={() => window.open('https://t.me/allindoge_dao', '__blank')} />
          </div>
        </div>
      </div>

      <div className='hidden md:block'>
        <div className='w-full max-w-[1280px] mx-[auto]'>
          <div className='relative h-[532px] bg-[#FDBA01] flex justify-center gap-[32px]'>
            <div className='relative z-100 h-full flex-grow flex flex-col justify-center items-start'>
              <img className='mt-[105px] w-[347px] h-[166px]' src={welcome} alt="" />
              <p className='w-[586px] text-[16px] mt-[44px]'>The global crypto trading MEME project initiated by Dogecoin traders，let us enter a crypto world full of challenges and opportunities。</p>
              <div className='mt-[28px] h-full  relative flex gap-[32px]'>
                <div className='relative z-100 w-[158px] h-[43px] flex items-center justify-center bg-[#ED3B23] rounded-[8px] border-[1px] border-b-[2px] border-[black] cursor-pointer'>Join Community</div>
                <div className='relative z-100 w-[158px] h-[43px] flex items-center justify-center bg-[white] rounded-[8px] border-[1px] border-b-[2px] border-[black] cursor-pointer'>Go to Swap</div>
              </div>
              <img className='absolute left-[30px] bottom-[0px] w-[231px] w-[232px] z-0 opacity-[0.3]' src={doge2} alt="" />
            </div>
            <div className='flex-grow flex items-end max-w-[571px]'>
              <img className='h-[451px] ' src={doge} alt="" />
            </div>
          </div>
        </div>
        <div className='border-[#000] h-[84px] bg-[#DFA504] flex items-center text-[32px] flex justify-between gap-[26px] px-[26px] text-[white]'>
          <span>All in Doge</span>
          <span>All in Doge</span>
          <span>All in Doge</span>
          <span>All in Doge</span>
          <span>All in Doge</span>
          <span>All in Doge</span>
        </div>
        <div className='bg-[rgb(255,243,212)]'>
          <div className='max-w-[1280px] w-full mx-[auto] px-[68px] py-[78px]'>
            <div className='flex mt-[85px] gap-[76px]'>
              <img className='h-[428px] w-[484px] grow bg-[#000] rounded-[23px]' src={media} alt="" />
              <div className='grow'>
                <img className='mt-[72px]' src={aboutAllInDoge} alt="" />
                <p className='text-[16px] w-[475px] mt-[38px] leading-[1rem]'>
                  All in Doge is not only a crypto project, but also a carnival community of global crypto traders. We come from all over the world, and our only goal is to go all out and move forward fearlessly!
                </p>
                <p className='text-[16px] w-[475px] mt-[20px]  leading-[1rem]'>
                  We have no roadmap and no ideals. We only care about the current market, focusing on trading, investment research, and the most important things in life - watching beauties and masturbating. We know that the crypto market is changing rapidly, and only with an **"All In"** mentality can we seize every opportunity and achieve extraordinary results.
                </p>
                <div className='relative mt-[33px] flex w-full justify-start gap-[16px]'>
                  <p className='rounded-[6px] border-[2px] border-b-[3px] border-[black] w-[158px] h-[43px] bg-[#ED3B23] flex items-center top-[0px] left-[0px] justify-center text-[16px]'>Join Community</p>
                  <p className='rounded-[6px] border-[2px] border-b-[3px] border-[black] w-[132px] h-[43px] bg-[#FDBA01] flex items-center top-[0px] left-[0px] justify-center text-[16px]'>Go to Swap</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='mt-[110px] relative max-w-[1280px] mx-auto'>
          <div className='mt-[116px]'>
            <img className='block mx-auto' src={ourCulture} alt="" />
            <p className='w-[656px] mt-[42px] mx-auto leading-[1rem]'>At All in Doge, we advocate an extreme trading culture - All in. Only by making the most of your investment can you reap the most rewards. Not being half-hearted, leaving no stone unturned, daring to take risks, and daring to challenge are the true wisdom of a trader.</p>
            <div className='flex justify-between  gap-[31px] mt-[52px]'>
              <div className='flex-auto w-[334px]'>
                <img className='w-full' src={culture01} alt="" />
                <p className='rounded-[6px] mt-[31px] mx-auto border-[2px] border-b-[3px] border-[black] w-[166px] h-[43px] bg-[#ED3B23] flex items-center top-[0px] left-[0px] justify-center text-[16px]'>No batch opening</p>
                <p className='w-full px-[10px] mt-[16px]  leading-[1rem] text-center'>
                  We believe that once you see an opportunity, you should immediately go all in. No waiting, no retreat, just full speed ahead.
                </p>
              </div>
              <div className='flex-auto w-[334px]'>
                <img className='w-full' src={culture02} alt="" />
                <p className='w-full rounded-[6px] mt-[31px] mx-auto border-[2px] border-b-[3px] border-[black]  w-[166px] h-[43px] bg-[#ED3B23] flex items-center top-[0px] left-[0px] justify-center text-[16px]'>No stop loss</p>
                <p className='w-full px-[10px] mt-[16px]  leading-[1rem] text-center'>
                  The market may go up or down, but every operation of All in Doge is an all-out attack. Persevere to the end and become the real winner!
                </p>
              </div>
              <div className='flex-auto w-[334px]'>
                <img className='w-full' src={culture03} alt="" />
                <p className='rounded-[6px] w-full mt-[31px] mx-auto border-[2px] border-b-[3px] border-[black] w-[178px] h-[43px] bg-[#ED3B23] flex items-center top-[0px] left-[0px] justify-center text-[16px]'>Community Wisdom</p>
                <p className='w-full px-[10px] mt-[16px]  leading-[1rem] text-center'>
                  We encourage every member to share their market insights, trading experience, and even investment research strategies. We are not just an investment platform, but a hotbed for the collision of ideas. The wisdom of trading comes from every one of you who dares to go all out.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='bg-[rgb(255,243,212)] pt-[25px] pb-[200px]'>
          <div className='mt-[110px] relative max-w-[1280px] mx-auto'>
            <div className='mt-[116px] relative'>
              <img className='block mx-auto' src={tokenomics} alt="" />
              <img className='w-full relative' src={tokenomics_bg} alt="" />
              <div className='absolute w-full left-[0px] top-[173px] '>
                <div className='absolute rounded-[6px] bg-[white] left-[0] top-[0] border-[3px] border-[black] px-[40px] py-[42px]'>
                  <div className='flex flex-col'>
                    <img src={launch} alt="" />
                    <p className='w-[300px] leading-[1rem] mt-[30px] text-[12px]'>
                      We have no pre-sale, no reserve, and every token comes from real market demand. Everyone has the opportunity to participate, it is fair and transparent.
                    </p>
                  </div>
                </div>
                <div className='absolute rounded-[6px] bg-[white] right-[0] bottom-[0] border-[3px] border-[black] px-[40px] py-[42px]'>
                  <div className='flex flex-col'>
                    <img src={no} className='w-[161px] mx-auto' alt="" />
                    <p className='w-[300px] leading-[1rem] mt-[30px] text-[12px]'>
                      We don’t have any early ideals or plans. We only focus on current transactions. You can enter the market at any time and go all in at any time.
                    </p>
                  </div>
                </div>
                <div>
                  <img src={circle} className='mx-auto' alt="" />
                </div>
              </div>
              <p className='text-[52px] text-center text-[600]'>100,000,000,000</p>
              <img className='block mx-auto' src={token_total} alt="" />
            </div>
          </div>
        </div>
        <div className='w-full bg-[rgb(255,243,212)]'>
          <div className='bg-[#FDBA01] rounded-tl-[36px] rounded-tr-[36px]'>
            <div className='max-w-[1280px] mx-auto border-[1px] border-[transparent]'>
              <div className='border-[4px] border-b-[4px]  mt-[-157px] px-[172px] py-[37px] rounded-[36px] border-[black] bg-[white] z-10000'>
                <img src={fomo} className='block mx-auto' alt="" />
                <p className='mt-[35px] text-center'>
                  Do you often see those money-making opportunities in the market pass by? Do you regret not taking action in advance when you see others making money?
                </p>
                <p className='mt-[35px] text-center'> All in Doge, so that you will never miss any trading opportunities. What we do is not just investment, but also a game about decision-making, courage and opportunity. Here, every action is full of possibilities, and every investment is full of power!
                  If you have made up your mind and don’t want to miss any opportunity to break out, then All in Doge is your only choice! Join us and start your own crypto trading journey!</p>
                <p className='mx-auto mt-[35px] rounded-[6px] border-[2px] border-b-[3px] border-[black] w-[158px] h-[43px] bg-[#ED3B23] flex items-center top-[0px] left-[0px] justify-center text-[16px]' onClick={() => window.open('https://x.com/allin_doge_', '__blank')}>Join Community</p>
              </div>
            </div>
          </div>
        </div>
        <div className='mt-[54px] pb-[132px]'>
          <div className='max-w-[1280px] mx-auto'>
            <img className='mx-auto' src={whyJoin} alt="" />
            <div className='flex  gap-[17px] mt-[37px]'>
              <div className='flex-1 border-[3px] border-[black] rounded-[12px] px-[31px] py-[37px] bg-[white]'>
                <div className='flex justify-between  h-[96px]'>
                  <img className='w-[229px]  h-[96px]' src={fearless} alt="" />
                  <img src={number1} className='w-[33px] h-[32px]' alt="" />
                </div>
                <p className='mt-[34px]'>
                  We believe that only by fully investing can we get the biggest return in this ever-changing market. You may have missed some opportunities, but today, you can still seize the present, all in!
                </p>
              </div>
              <div className='flex-1 border-[3px] border-[black] rounded-[12px] px-[31px] py-[37px] bg-[white]'>
                <div className='flex justify-between  h-[96px]'>
                  <img src={global} className='h-[64px]' alt="" />
                  <img src={number2} className='w-[33px] h-[32px]' alt="" />
                </div>
                <p className='mt-[34px]'>
                  Traders from all over the world gather together. There is no indifference, no walls, only partners who understand each other and share the fun and wisdom of trading with you.
                </p>
              </div>
              <div className='flex-1 border-[3px] border-[black] rounded-[12px] px-[31px] py-[37px] bg-[white]'>
                <div className='flex justify-between h-[96px]'>
                  <img className='w-[229px] h-[96px]' src={continuously} alt="" />
                  <img src={number3} className='w-[33px] h-[32px]' alt="" />
                </div>
                <p className='mt-[34px]'>
                  In All in Doge, you are no longer alone. You can discuss market trends, analyze market conditions, and share trading strategies with other members of the community at any time. There are no "experts" here, only partners who are willing to share and learn.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='bg-[black] pt-[49px] pb-[49px]'>
          <img className='mx-auto' src={dont} alt="" />
          <img className='mt-[46px] mx-auto' src={opp} alt="" />
          <div className='flex mt-[30px] justify-center gap-[8px]'>
          
            <img className='w-[32px] cursor-pointer border-[2px] rounded-[3px] border-black' src={x} alt="" onClick={() => window.open('https://x.com/allin_doge_', '__blank')} />
            <img className='w-[32px] cursor-pointer border-[2px] rounded-[3px] border-black' src={tg} alt="" onClick={() => window.open('https://t.me/allindoge_dao', '__blank')} />
          </div>
        </div>
      </div>
    </>
  )
}