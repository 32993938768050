import logo from '@/assets/images/header/logo.svg'
import tg from '@/assets/images/header/tg.svg'
import x from '@/assets/images/header/x.svg'

export const Header = () => (
  <div className="hidden md:flex h-[68px] min-h-[68px] items-center px-[94px] bg-[#FDBA01] text-[white] justify-between">
    <img src={logo} className='w-[126px] h-auto' />
    <div className='flex items-center justify-between gap-[53px]'>
      <div className='flex items-center justify-center gap-[40px] text-[black] font-[AaLaoJieZhaoPaiTi]'>
        <span>Home</span>
        <span>Culture</span>
        <span>Token</span>
        <span>Community</span>
      </div>
      <div className='flex items-center gap-[16px]'>
        <img className='w-[32px] cursor-pointer border-[2px] rounded-[3px] border-black' src={x} alt="" onClick={() => window.open('https://x.com/allin_doge_', '__blank')} />
        <img className='w-[32px] cursor-pointer border-[2px] rounded-[3px] border-black' src={tg} alt="" onClick={() => window.open('https://t.me/allindoge_dao', '__blank')} />
      </div>
    </div>
  </div>
)